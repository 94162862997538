import React from 'react';
import { useTranslation } from 'react-i18next';

import PlayNowButonDiv from '../PlayNowButonDiv';
import Heading from '../Heading';
import LogoClickHandlerDiv from '../logoClickHandlerDiv';
import Footnote from '../../components/Footnote';

import desktopBg_1 from '../../img/bg/hero-desktop-1.jpg';
import desktopBg_2 from '../../img/bg/hero-desktop-2.jpg';
import desktopBg_3 from '../../img/bg/hero-desktop-3.jpg';
import desktopBg_4 from '../../img/bg/hero-desktop-4.jpg';

import mobileBg_1 from '../../img/bg/hero-mobile-1.jpg';
import mobileBg_2 from '../../img/bg/hero-mobile-2.jpg';
import mobileBg_3 from '../../img/bg/hero-mobile-3.jpg';
import mobileBg_4 from '../../img/bg/hero-mobile-4.jpg';

const Hero = () => {

  const { t } = useTranslation();
  let carousel = false;

  const initCarousel = ( imgs ) => {
    if ( carousel ) {
      const bgs = document.querySelectorAll( imgs );

      setInterval( () => {
        for ( let i = 0; i < bgs.length; i++ ) {
          const bg = bgs[ i ],
            first = 0,
            next = i + 1;

          if ( next === bgs.length ) {
            bg.classList.remove( 'active' );
            bgs[ first ].classList.add( 'active' );
            break;
          }

          if ( bg.classList.contains( 'active' ) ) {
            bg.classList.remove( 'active' );
            bgs[ next ].classList.add( 'active' );
            break;
          }
        }
      }, 3000 );
    }
  }

  window.addEventListener( 'load', () => {
    setTimeout(() => {
      carousel = true;
      initCarousel( '.heroBgMobile .heroBgImg' );
      initCarousel( '.heroBgDesktop .heroBgImg' );
    }, 500);
  } );

  return (
    <div id="Home" className="hero scrollspy" title={ t( 'mainHeaderImgAlt' ) }>

      <div className="heroBg heroBgMobile">
        <div className="heroBgImg active" style={ { backgroundImage: `url(${ mobileBg_1 })` } }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ mobileBg_2 }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ mobileBg_3 }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ mobileBg_4 }></div>
      </div>

      <div className="heroBg heroBgDesktop">
        <div className="heroBgImg active" style={ { backgroundImage: `url(${ desktopBg_1 })` } }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ desktopBg_2 }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ desktopBg_3 }></div>
        <div className="heroBgImg lazyload blurUp" data-bg={ desktopBg_4 }></div>
      </div>

      <div className="heroContent">
        <LogoClickHandlerDiv />

        <div className="container">
          <PlayNowButonDiv />
        </div>

        <Heading
          part2={ t( 'cta.title.part2' ) }
          classes={ "text--gold text--md" }
        />
        <Footnote message={ t( 'cta.footnote' ) } />
      </div>
    </div>
  );
}

export default Hero;
