import React from 'react';

const Heading = ( { part1, part2, classes } ) => {
  return (
    <div className="headingMain">
      <h2 className={ classes }>
        { part1 &&
          part1 }
        <span className={ `headingMain--lg ${ part1 ? '' : 'mt-0' }` }>{ part2 }</span>
      </h2>
    </div>
  )
}

export default Heading;
