import React from 'react';

const ExtraFeature = ( { title, img, alt } ) => {
  return (
    <div className="extraFeature">
      <div className="extraFeatureImgWrap">
        <div>
          <img data-src={ img } alt={ alt } className="lazyload imgFull" />
        </div>
      </div>

      <div className="extraFeatureTitle text--gold">
        { title }
      </div>
    </div>
  );
}

export default ExtraFeature;
