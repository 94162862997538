import React from 'react';
import { useTranslation } from 'react-i18next';
import zyngaLogo from '../../img/zynga-logo.svg';
import hboLogo from '../../img/hbo-logo.svg';

const Footer = () => {

  const { t, i18n } = useTranslation();

  const policy = ( lang ) => {
    switch ( lang ) {
      case "fr": return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/fr/privacy/policy">Politique de confidentialité</a>, ' et aux ', <a target="_blank" key="1" rel="noopener noreferrer" href="https://www.zynga.com/fr/legal/terms-of-service">Conditions d'utilisation</a>, ' de Zynga.' ]
      case "de": return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/de/privacy/policy"> Datenschutzrichtlinie</a>, ' und ', <a target="_blank" key="2" rel="noopener noreferrer" href="https://www.zynga.com/de/legal/terms-of-service">Nutzungsbedingungen</a>, ' verwendet.' ]
      case "es": return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/es/privacy/policy">Política de privacidad</a>, ' y las ', <a target="_blank" key="3" rel="noopener noreferrer" href="https://www.zynga.com/es/legal/terms-of-service">Condiciones del servicio</a>, ' de Zynga.' ]
      case "it": return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/it/privacy/policy">Informativa sulla privacy</a>, ' e dei ', <a target="_blank" key="4" rel="noopener noreferrer" href="https://www.zynga.com/it/legal/terms-of-service">Termini di servizio</a>, ' di Zynga.' ]
      case "tr": return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/tr/privacy/policy">Gizlilik Politikası</a>, ' ve ', <a target="_blank" key="5" rel="noopener noreferrer" href="https://www.zynga.com/tr/legal/terms-of-service">Hizmet Şartlarına</a>, ' uygun şekilde kullanılacaktır.' ]
      default: return [ <a target="_blank" key={ lang } rel="noopener noreferrer" href="https://www.zynga.com/privacy/policy">Privacy Policy</a>, ' and ', <a target="_blank" key="6" rel="noopener noreferrer" href="https://www.zynga.com/legal/terms-of-service">Terms of Service</a> ]
    }
  }

  return (
    <footer className="footer">
      <div className="footer__logos">
        <img data-src={ hboLogo } className="footer__logos__item lazyload" alt="HBO" />
        <img data-src={ zyngaLogo } className="footer__logos__item lazyload" alt="Zynga" />
      </div>
      <small>
        <p>
          <small>{ t( 'disclaimer.part1' ) } </small>
          <small>{ t( 'disclaimer.part2' ) } </small>
          { t( 'footer' ) } { policy( i18n.language ) }
          <small>{ t( 'disclaimer.part3' ) }</small>
        </p>
      </small>
    </footer>
  );
}

export default Footer;
