import React from 'react';

const ChevronDown = () => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.6737 2.46053C8.76648 2.55333 8.76648 2.70336 8.6737 2.79616L4.53943 6.93044C4.44664 7.02323 4.29666 7.02323 4.20386 6.93044L0.0695775 2.79616C-0.0231925 2.70336 -0.0231925 2.55333 0.0695775 2.46053L0.460569 2.0696C0.553339 1.9768 0.70335 1.9768 0.79614 2.0696L4.3717 5.63722L7.94714 2.0696C8.03994 1.9768 8.19005 1.9768 8.28283 2.0696L8.6737 2.46053Z" fill="currentColor" />
    </svg>
  );
}

export default ChevronDown;
