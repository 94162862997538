import React from 'react';
import { useTranslation } from 'react-i18next';

import Video from '../Video';

const Trailer = () => {

  const { t } = useTranslation();

  return (
    <section id="Trailer" className="video scrollspy">
      <h2 className="video__title text--gradient-trailer text--lg ">{ t( 'videoTitle' ) }</h2>
      <Video />
    </section>
  );
}

export default Trailer;
