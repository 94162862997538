import React from 'react';

const Twiiter = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M45.5 0H6.5C4.77609 0 3.12279 0.684819 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5L0 45.5C0 47.2239 0.684819 48.8772 1.90381 50.0962C3.12279 51.3152 4.77609 52 6.5 52H45.5C47.2239 52 48.8772 51.3152 50.0962 50.0962C51.3152 48.8772 52 47.2239 52 45.5V6.5C52 4.77609 51.3152 3.12279 50.0962 1.90381C48.8772 0.684819 47.2239 0 45.5 0V0ZM38.92 19.37C38.92 19.66 38.92 19.95 38.92 20.23C38.92 29.05 32.22 39.23 19.92 39.23C16.2959 39.2321 12.7479 38.1907 9.7 36.23C10.2278 36.2904 10.7587 36.3205 11.29 36.32C14.2917 36.3264 17.2082 35.3225 19.57 33.47C18.1783 33.4448 16.8293 32.9848 15.7121 32.1546C14.5949 31.3243 13.7655 30.1653 13.34 28.84C13.7552 28.9205 14.1771 28.9607 14.6 28.96C15.1916 28.9617 15.7806 28.8809 16.35 28.72C14.8411 28.4173 13.4836 27.6014 12.5083 26.4109C11.5329 25.2205 11 23.729 11 22.19C10.9951 22.1602 10.9951 22.1298 11 22.1C11.9194 22.6136 12.9476 22.9015 14 22.94C12.592 21.9838 11.6013 20.527 11.2297 18.866C10.8582 17.2051 11.1336 15.465 12 14C13.6715 16.0673 15.7599 17.7592 18.129 18.9653C20.4982 20.1714 23.0949 20.8647 25.75 21C25.6293 20.5024 25.5689 19.992 25.57 19.48C25.5633 18.1379 25.9617 16.825 26.713 15.7129C27.4643 14.6008 28.5337 13.7412 29.7813 13.2465C31.0289 12.7518 32.3967 12.645 33.706 12.9399C35.0153 13.2349 36.2051 13.9181 37.12 14.9C38.6127 14.6074 40.0437 14.0593 41.35 13.28C40.8595 14.8357 39.8175 16.1587 38.42 17C39.7396 16.8605 41.0307 16.5234 42.25 16C41.3503 17.3133 40.2225 18.4547 38.92 19.37V19.37Z" fill="#D89E0F" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Twiiter;
