import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import BookmarkHelper from 'react-bookmark';

import Star from './Icons/Star';

const Bookmark = () => {

  Modal.setAppElement( '#root' );

  const { t } = useTranslation(),
    [ modalIsOpen, setIsOpen ] = useState( false ),
    openModal = () => setIsOpen( true ),
    closeModal = () => setIsOpen( false );

  return (
    <div>
      <button onClick={ openModal } className="bookmark">
        <span><Star /> { t( 'general.addBookmark' ) }</span>
      </button>
      <Modal
        isOpen={ modalIsOpen }
        onRequestClose={ closeModal }
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div style={ { textAlign: 'center' } }>
          <BookmarkHelper
            className="bookmarkHelper"
            press={ t( 'general.press' ) }
            addPage={ t( 'general.bookmarkMessageToPage' ) }
            addHome={ t( 'general.addToHomeScreen' ) }
            addHomeMessage={ t( 'general.bookmarkMessageToHome' ) }
            nextStep={ t( 'general.bookmarkToHomeNextStep' ) }
          />
          <button onClick={ closeModal } className="PlayNowBtn modalClose">{ t( 'general.ok' ) }</button>
        </div>
      </Modal>
    </div>
  );
}

export default Bookmark;
