import React from 'react';
import { useTranslation } from 'react-i18next';
import { isPlayButtonAllowed, onClickPlayNow } from '../common/Helpers';

const LogoClickHandlerDiv = () => {
  const { t } = useTranslation();

  return (
    <>
      {
        isPlayButtonAllowed &&
        <div className="logoClickHandler" onClick={ () => onClickPlayNow( t( 'seo.link.hreflang' ) ) }>
          <h1>
            <span className="srOnly"> { t( 'mainHeaderImgAlt' ) } </span>
          </h1>
        </div>
      }
    </>
  )
}

export default LogoClickHandlerDiv;
