import React from 'react';

const Star = () => {
  return (
    <svg className="iconSvg" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.69533 1.48701L5.86449 5.10687L1.76824 5.68922C1.03366 5.79311 0.739266 6.67621 1.27198 7.182L4.23552 9.99806L3.53459 13.9761C3.40842 14.6951 4.18505 15.2337 4.83552 14.8975L8.5 13.0192L12.1645 14.8975C12.8149 15.231 13.5916 14.6951 13.4654 13.9761L12.7645 9.99806L15.728 7.182C16.2607 6.67621 15.9663 5.79311 15.2318 5.68922L11.1355 5.10687L9.30467 1.48701C8.97664 0.841772 8.02617 0.83357 7.69533 1.48701Z" fill="currentColor" />
    </svg>
  );
}

export default Star;
