import React from 'react';

import badgeAppStoreEn from '../img/badges/appstore_en.png';
import badgeGooglePlayEn from '../img/badges/googleplay_en.png';
import badgeKfEn from '../img/badges/kf_en.png';

const DownloadPlatforms = () => {

  const btns = [
    {
      label: 'App Store',
      url: 'https://itunes.apple.com/us/app/game-of-thrones-slots-casino/id1369317521?ls=1&amp;mt=8',
      img: badgeAppStoreEn,
      id: 'app-store',
    },
    {
      label: 'Google Play',
      url: 'https://play.google.com/store/apps/details?id=com.zynga.gotslots',
      img: badgeGooglePlayEn,
      id: 'google-play',
    },
    {
      label: 'Amazon Kindle Fire',
      url: 'http://www.amazon.com/gp/mas/dl/android?p=com.zynga.gotslots',
      img: badgeKfEn,
      id: 'amazon-kindle-fire',
    }
  ];

  return (
    <div className="storeBtns">
      { btns.map( ( btn, i ) => (
        <a role="button" key={ btn.id } aria-label={ btn.label } href={ btn.url } className="storeBtns__item" >
          <img data-src={ btn.img } alt={ btn.label } className="lazyload" />
        </a>
      ) ) }
    </div>
  )
}

export default DownloadPlatforms;
