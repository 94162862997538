import React from 'react';
import { useTranslation } from 'react-i18next';
import { isPlayButtonAllowed, isPlayButtonDisabled, onClickPlayNow } from '../common/Helpers';

const PlayNowButonDiv = () => {

  const { t } = useTranslation();

  if ( isPlayButtonDisabled() ) {
    return (
      <div></div>
    );
  }

  if ( isPlayButtonAllowed() ) {
    return (
      <div className="facebook_container">
        <div onClick={ () => onClickPlayNow( t( 'seo.link.hreflang' ) ) } className="PlayNowBtn">
          <h1>{ t( 'playNow' ) }</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="facebook_container">
      <h1>Your device or browser is not supported!</h1>
    </div>
  )
};

export default PlayNowButonDiv;
