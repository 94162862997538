import React, { Component } from 'react';

import Facebook from './Icons/Facebook';
import Twitter from './Icons/Twiiter';
import Email from './Icons/Email';

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

class Share extends Component {
  render () {
    const shareUrl = window.location.href;
    const title = this.props.title;
    const emailBody = `${ shareUrl } \n\n ${ this.props.desc }`;

    return (
      <div className="shareList">
        <div className="shareList__item">
          <FacebookShareButton
            url={ shareUrl }
            quote={ this.props.desc }
            additionalProps={ { "aria-label": "Facebok" } }
            className="shareList__button">
            <Facebook />
          </FacebookShareButton>
        </div>

        <div className="shareList__item">
          <TwitterShareButton
            url={ shareUrl }
            title={ this.props.desc }
            hashtags={ [ 'GameofThronesSlotsCasino', 'Zynga' ] }
            additionalProps={ { "aria-label": "Twitter" } }
            className="shareList__button">
            <Twitter />
          </TwitterShareButton>
        </div>

        <div className="shareList__item">
          <EmailShareButton
            url={ shareUrl }
            subject={ title }
            body={ emailBody }
            additionalProps={ { "aria-label": "Email" } }
            className="shareList__button">
            <Email />
          </EmailShareButton>
        </div>

      </div>
    );
  }
}

export default Share;
