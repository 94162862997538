import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import FontFaceObserver from 'fontfaceobserver';
import Helmet from 'react-helmet';
import LoadingScreen from 'react-loading-screen';
import './common/lazysizes-bg';
import 'lazysizes';

import Header from './components/Layout/Header';
import Hero from './components/Layout/Hero';
import Trailer from './components/Layout/Trailer';
import Features from './components/Layout/Features';
import Footer from './components/Layout/Footer';

import Divider from './components/Divider';
import PlayNowButonDiv from './components/PlayNowButonDiv';
import Bookmark from './components/Bookmark';
import Share from './components/Share';
import Cta from './components/Cta';
import ExtraFeature from './components/ExtraFeature';
import FAQ from './components/FAQ';

import redDragon from './img/raise_dragon.png';
import featImg1 from './img/feat/join_a_house.png';
import featImg2 from './img/feat/map.png';
import featImg3 from './img/feat/take_throne.png';

import './scss/App.scss';

// Load fonts.
var fontA = new FontFaceObserver( 'TrajanPro-Bold' );
var fontB = new FontFaceObserver( 'Gotham-Medium' );
Promise.all( [ fontA.load(), fontB.load() ] ).then( () => document.querySelector( 'body' ).classList.add( 'font-loaded' ) );

const Page = () => {

  const { t, i18n } = useTranslation();

  // Get localised data.
  const faqData = t( 'faq.data', { returnObjects: true } );
  const featuresTwoData = t( 'featuresTwo.data', { returnObjects: true } );
  const featurestTwoImgs = [ featImg1, featImg2, featImg3 ];

  return (
    <div className={ `App lang-${ i18n.language }` }>

      <Helmet>
        <title>{ t( 'seo.title' ) }</title>
        <meta name="description" content={ t( 'seo.description' ) } />
        <meta name="keywords" content={ t( 'seo.keywords' ) } />
        <meta property="og:title" content={ t( 'seo.og.title' ) } />
        <meta property="og:description" content={ t( 'seo.og.description' ) } />
        <meta name="twitter:site" content={ t( 'seo.twitter.site' ) } />
        <meta name="twitter:title" content={ t( 'seo.twitter.title' ) } />
        <meta name="twitter:description" content={ t( 'seo.twitter.description' ) } />
        <html lang={ i18n.language } />
      </Helmet>

      <Suspense fallback={ <Loader /> }>

        <Header />

        <main>
          <Hero />

          <section className="feturesExtra">
            <div className="bgPattern">
              <div className="container">
                <div className="extraFeatures">
                  { featuresTwoData.map( ( feat, i ) => <ExtraFeature key={ i } title={ feat.title } img={ featurestTwoImgs[ i ] } alt={ feat.imgAlt } /> ) }
                </div>
              </div>
            </div>
          </section>

          <section className="about">
            <div className="aboutBg"></div>
            <div className="container">

              <Trailer />

              <div id="About" className="aboutDesc scrollspy">
                <div className="aboutDesc__col">
                  <h2 className="titleLg text--gold">
                    { t( 'features.part1.title' ) }
                    <span className="featureSubTitle">{ t( 'features.part1.subtitle' ) }</span>
                  </h2>
                  <p>{ t( 'features.part1.desc' ) }</p>
                </div>
                <div className="aboutDesc__col">
                  <span className="helper"></span>
                  <img data-src={ redDragon } alt="Dragon" className="imgFluid lazyload" style={ { verticalAlign: 'middle' } } />
                </div>
              </div>

            </div>
          </section>

          <Features />

          <div className="container">
            <PlayNowButonDiv />
          </div>

          <Divider />

          <section id="FAQ" className="faq scrollspy">
            <div className="container">
              <h2 className="textCenter titleLg text--gold extraMargin">{ t( 'faq.title' ) }</h2>
              <FAQ>
                { faqData.map( ( faq, i ) => (
                  <FAQ.QAItem key={ i }>
                    <FAQ.Question answerId={ `q-${ i }` }>
                      { () => <span>{ faq.question }</span> }
                    </FAQ.Question>
                    <FAQ.Answer id={ `q-${ i }` }> { faq.answer } </FAQ.Answer>
                  </FAQ.QAItem>
                ) ) }
              </FAQ>
            </div>
          </section>

          <div className="container">
            <PlayNowButonDiv />
          </div>

          <section id="Download">
            <Cta
              titlePart2={ t( 'cta.title.part2' ) }
              titlePart3={ t( 'cta.title.part3' ) }
              footnote={ t( 'cta.footnote' ) }
              classes={ "text--gold text--md" }
            />
          </section>

          <div className="container">
            <div className="share shareFooter">
              <h3 className="text--md text--gradient-share">{ t( 'shareTitle' ) }</h3>
              <Share title={ t( 'seo.title' ) } desc={ t( 'seo.description' ) } />
              <Bookmark />
            </div>
          </div>

        </main>

        <Footer />

      </Suspense>
    </div>
  );
}

// loading component for suspence fallback
const Loader = () => (
  <LoadingScreen
    loading={ true }
    bgColor='#000'
    spinnerColor='#d89e0f'
    textColor='#fff'
    children=''
    logoSrc=''
  />
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App () {
  return (
    <Suspense fallback={ <Loader /> }>
      <Page />
    </Suspense>
  );
}
