import React from 'react';

const Facebook = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M49.13 0H2.87C2.10883 0 1.37883 0.302374 0.840604 0.840604C0.302374 1.37883 0 2.10883 0 2.87L0 49.13C0 49.8912 0.302374 50.6212 0.840604 51.1594C1.37883 51.6976 2.10883 52 2.87 52H27.78V31.89H21V24H27.76V18.23C27.76 11.51 31.86 7.86 37.85 7.86C39.8742 7.85065 41.8973 7.95415 43.91 8.17V15.17H39.78C36.53 15.17 35.9 16.72 35.9 18.99V23.99H43.7L42.7 31.86H35.9V52H49.13C49.8912 52 50.6212 51.6976 51.1594 51.1594C51.6976 50.6212 52 49.8912 52 49.13V49.13V2.87C52 2.10883 51.6976 1.37883 51.1594 0.840604C50.6212 0.302374 49.8912 0 49.13 0V0Z" fill="#D89E0F" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;
