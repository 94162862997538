import React from 'react';

const Divider = ( { type } ) => {
  return (
    <div className={ `dividerWrap ${ type === 'small' ? 'dividerWrap--sm' : '' }` }>
      <div className="divider"></div>
    </div>
  );
}

export default Divider;

