import React from 'react';
import { useTranslation } from 'react-i18next';

import Feature from '../Feature';
import Divider from '../Divider';

import cersei from '../../img/feat/cersei.jpg';
import sansa from '../../img/feat/sansa.jpg';
import collection1 from '../../img/feat/collections-1.jpg';
import collection2 from '../../img/feat/collections-2.jpg';
import highGarden1 from '../../img/feat/highgarden-1.jpg';
import highGarden2 from '../../img/feat/highgarden-2.jpg';

const Features = () => {

  const { t } = useTranslation();

  const data = [
    {
      title: t( 'features.part2.title' ),
      subtitle: t( 'features.part2.subtitle' ),
      desc: t( 'features.part2.desc' ),
      key: 'feature-1',
      gallery: [
        {
          src: cersei,
          alt: 'Play Epic Slots',
          key: 'feat-img-1',
        },
        {
          src: sansa,
          alt: 'Win Big',
          key: 'feat-img-6',
        },
      ]
    },
    {
      title: t( 'features.part3.title' ),
      subtitle: t( 'features.part3.subtitle' ),
      desc: t( 'features.part3.desc' ),
      key: 'feature-2',
      gallery: [
        {
          src: collection2,
          alt: 'Collect & Upgrade',
          key: 'feat-img-3',
        },
        {
          src: collection1,
          alt: 'Quest',
          key: 'feat-img-2',
        },
      ]
    },
    {
      title: t( 'features.part4.title' ),
      subtitle: t( 'features.part4.subtitle' ),
      desc: t( 'features.part4.desc' ),
      key: 'feature-3',
      gallery: [
        {
          src: highGarden1,
          alt: 'Create or Join',
          key: 'feat-img-4',
        },
        {
          src: highGarden2,
          alt: 'Westeros',
          key: 'feat-img-5',
        },
      ]
    },
  ];

  return (
    <section id="Features" className="features scrollspy">
      <div className="container">
        { data.map( ( { title, subtitle, desc, key, gallery }, i ) => (
          < React.Fragment key={ key }>
            <Feature key={ key } title={ title } subtitle={ subtitle } desc={ desc } imgData={ gallery } />
            { ( data.length - 1 ) > i && <Divider /> }
          </React.Fragment>
        ) ) }
      </div>
    </section>
  );
}

export default Features;
