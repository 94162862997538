import React from 'react';

const Feature = ( { title, subtitle, desc, descLineTwo, imgData } ) => {

  return (
    <div className="featureContent">

      <div className="featureDesc">
        <h2 className="titleLg text--gold">
          { title }
          <span className="featureSubTitle">{ subtitle }</span>
        </h2>
        <p className="lead">{ desc }</p>
        { descLineTwo &&
          <p className="lead">{ descLineTwo }</p> }
      </div>

      <div className="featureImgContainer">
        { imgData.map( img => (
          <div className="featureImgWrap" key={ img.key }>
            <div>
              <img data-src={ img.src } alt={ img.alt } className="featureImg lazyload" />
            </div>
          </div>
        ) ) }
      </div>

    </div>
  );
}

export default Feature;
