import React from 'react';

const Footnote = ( { message } ) => {
  return (
    <div className="cta__footnote">
      <p>{ message }</p>
    </div>
  )
}

export default Footnote;
