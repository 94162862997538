import React from 'react';
import DownloadPlatforms from './DownloadPlatforms';
import Heading from './Heading';
import Footnote from './Footnote';


const Cta = ( { titlePart1, titlePart2, classes, footnote } ) => {

  return (
    <div className="cta">
      <div className="container">
        <Heading part1={ titlePart1 } part2={ titlePart2 } classes={ classes } />
        <DownloadPlatforms />
        <Footnote message={ footnote } />
      </div>
    </div>
  );
}

export default Cta;
