import React from 'react';

const Email = () => {
  return (
    <svg width="52" height="42" viewBox="0 0 52 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M46.8 9.6534e-06H5.20001C4.51677 -0.00130808 3.83999 0.132295 3.2085 0.393152C2.57701 0.65401 2.00324 1.03699 1.52012 1.52012C1.03699 2.00324 0.65401 2.57701 0.393152 3.2085C0.132295 3.83999 -0.00130808 4.51677 9.6534e-06 5.20001V36.4C0.00527741 37.7775 0.554824 39.0971 1.52887 40.0711C2.50292 41.0452 3.82251 41.5947 5.20001 41.6H46.8C48.1775 41.5947 49.4971 41.0452 50.4711 40.0711C51.4452 39.0971 51.9947 37.7775 52 36.4V5.20001C51.9947 3.82251 51.4452 2.50292 50.4711 1.52887C49.4971 0.554824 48.1775 0.00527741 46.8 9.6534e-06V9.6534e-06ZM46.8 36.4H5.20001V10.4L26 23.4L46.8 10.4V36.4ZM26 18.2L5.20001 5.20001H46.8L26 18.2Z" fill="#D89E0F" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="52" height="41.6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Email;
