import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isSafari } from 'react-device-detect';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import LangList from '../LangItem';
import Share from '../Share';
import Bookmark from '../Bookmark';
import BannerDiv from '../BannerDiv';
import ChevronDown from '../Icons/ChevronDown';

import gotSlotsLogo from '../../img/logo.png';

const Header = () => {
  const [ mobileMenu, setMobileMenu ] = useState( false );
  const { t, i18n } = useTranslation();

  const langs = {
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
    de: 'Deutsch',
    es: 'Español',
    tr: 'Türkçe',
  }

  const toTop = () => {
    if ( isSafari ) {
      document.body.scrollTop = 0;
    } else {
      document.documentElement.scrollTop = 0;
    }
  }

  const langMenuToggle = () => {
    document.querySelector( '.bubble' ).classList.toggle( 'show' );
    document.querySelector( '.lang__selected' ).classList.toggle( 'opened' );
  }

  const toggleMobileMenu = () => {
    if ( !mobileMenu ) {
      document.querySelector( '#btn-menu' ).classList.add( 'is-active' );
      setMobileMenu( true );
    }

    if ( mobileMenu ) {
      document.querySelector( '#btn-menu' ).classList.remove( 'is-active' );
      setMobileMenu( false );
    }
  }

  const handleMenu = () => {
    if ( mobileMenu ) {
      toggleMobileMenu();
    }
  }

  const changeLanguage = lng => {
    i18n.changeLanguage( lng );
    langMenuToggle();
    toTop();

    if ( mobileMenu ) {
      toggleMobileMenu();
    }
  }

  // Scrollspy
  const sections = [ 'Home', 'Trailer', 'About', 'Features', 'FAQ' ];

  return (
    <header className={ `header ${ mobileMenu ? 'menuOpen' : '' }` }>
      <div className="container">
        <div className="header__inner">

          <div className="header__logoWrap">
            <span className="helper"></span>
            <img
              src={ gotSlotsLogo }
              alt="Game of Thrones Slots Casino"
              className="header__logo"
            />
          </div>

          <nav id="main-menu" className="header__nav">
            <Scrollspy items={ sections } currentClassName="is-current">
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#Home" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.home' ) }</AnchorLink></li>
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#Trailer" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.trailer' ) }</AnchorLink></li>
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#About" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.about' ) }</AnchorLink></li>
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#Features" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.features' ) }</AnchorLink></li>
              <li><a offset={ `${ isMobile ? 70 : 50 }` } href="https://store.gotslotscasino.zynga.com/" target="_blank" className="menu-item">{ t( 'navigation.store' ) }</a></li>
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#FAQ" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.faq' ) }</AnchorLink></li>
              <li><AnchorLink offset={ `${ isMobile ? 70 : 50 }` } href="#Download" onClick={ handleMenu.bind( this ) } className="menu-item">{ t( 'navigation.download' ) }</AnchorLink></li>
            </Scrollspy>
          </nav>

          <div className="header__share">
            <Share title={ t( 'seo.title' ) } desc={ t( 'seo.description' ) } />
          </div>

          <Bookmark />

          <button id="btn-menu" onClick={ toggleMobileMenu.bind( this ) } className="hamburger hamburger--slider hamburger--collapse" type="button" aria-label="Menu">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <div className={ `lang ${ mobileMenu ? 'show' : '' }` }>
            <button className="lang__selected" onClick={ langMenuToggle.bind( this ) }>{ t( 'fullLang' ) } <ChevronDown /></button>
            <div className="bubble">
              <ul className="langList">
                <div>
                  { Object.entries( langs ).map( ( entry ) => ( entry[ 0 ] !== i18n.language ) ? <LangList click={ () => changeLanguage( entry[ 0 ] ) } lang={ entry[ 1 ] } key={ entry[ 0 ] } /> : null ) }
                </div>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div className="container">
        <BannerDiv />
      </div>
    </header>
  );
}

export default Header;

