import React, { Component } from 'react';
import borderCorner from '../img/video-border-corner.png';
import VideoPlayer from 'react-video-js-player';
import video from '../videos/promo-trailer.mp4';
import poster from '../img/video-poster.jpg';

class Video extends Component {
  player = {}
  state = {
    video: {
      src: video,
      poster: poster,
    }
  }

  render () {
    const cornes = [ 'topLeft', 'topRight', 'bottomLeft', 'bottomRight' ];
    return (
      <div className="embed-wrap">
        { cornes.map( corner => <img key={ corner } data-src={ borderCorner } alt="Border Corner" className={ `video__corner video__corner--${ corner } lazyload` } /> ) }
        <VideoPlayer
          controls={ true }
          src={ this.state.video.src }
          poster={ this.state.video.poster }
          className="embed-responsive embed-responsive-16by9"
        />
      </div>
    );
  }
}

export default Video;
