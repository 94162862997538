import React from 'react';
import { browserVersion, isChrome, isFirefox, isEdge, isSafari, isMobile, isAndroid, isIOS } from 'react-device-detect';

export const isPlayButtonDisabled = () => {
  if ( window.disable_play_btn ) {
    return true;
  }
  return false;
};

export const isPlayButtonAllowed = () => {
  if ( isMobile ) {
    return isAndroid || isIOS;
  }

  if ( isSafari ) {
    return true;
  }

  if ( isFirefox && browserVersion >= 51 ) {
    return true;
  }

  if ( isChrome && browserVersion >= 56 ) {
    return true;
  }

  if ( isEdge && browserVersion >= 11 ) {
    return true;
  }

  return false;
};

export const GetPlayNowURL = hreflang => {

  if ( isMobile ) {
    return "https://gotslots.onelink.me/bOrX/dotcommobile";
  }
  else {
    if ( window.game_url ) {
      var url = new URL( window.game_url );
      url.searchParams.append( 'l', hreflang );
      return url;
    } else {
      return null;
    }
  }
}

export const onClickPlayNow = hreflang => {
  var url = GetPlayNowURL( hreflang );

  if ( url ) {
    window.location = url;
  }
};

export const maintenanceMessage = hreflang => {
// example text
// return (<span> We are now part of the Take-Two group of companies. Our Privacy Policy will change on the 29th of June 2023. <a href="https://www.take2games.com/privacy/en-US"> New Privacy Policy </a></span>);
  if ( window.maintenance_message ) {
    return (<span>{ window.maintenance_message } { window.maintenance_message_url ? <a href={ window.maintenance_message_url } target="_blank">More Info</a> : null }</span>);
  }
  return null;
}