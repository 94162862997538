import React from 'react';
import { maintenanceMessage } from '../common/Helpers';

const BannerDiv = () => {
    const Message = maintenanceMessage();
    if ( Message ) {
        return (
            <div className="banner">
                <div className="bannerHeader">
                    { Message }
                </div>
            </div>
        );
    }

    return null;
}

export default BannerDiv;